import React from "react";
import { Helmet } from "react-helmet";
import WaveReverse from "../sections/common/WaveReverse";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/examples/Hero";
import VideoDemo from "../sections/common/VideoDemoNew";

import Commit from "../sections/common/Cta";

import SEO from '../components/SEO';
import imgC from "../assets/image/metaimg/showcase.jpg";

const Demo = () => {
  return (  
    <>
   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Showcasing the very best of Digma's Interactive Videos"
          description="Interactive Art galleries, Hotel Tours, Assembly Videos, Real Estate Brochures and Video Resumes "
          image={imgC}
        />    
        <Helmet>        
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=gallery'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=gallery'}></script>
        </Helmet>                 
        <Hero />
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <VideoDemo className="grey" title="Art Gallery" name="Arts & Entertainment" projectId="e3e2de77-696b-4dc9-b0c4-633feb0a6dbe" />
        <WaveReverse background="#F7F9FC" color="#fff" />         
        <VideoDemo title="Drone Hotel Tour" name="Leisure & tourism" projectId="14636da6-686e-465c-8165-a48b9e692a3f" />      
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <VideoDemo className="grey" title="Ritual Gym" name="Instructional Guides" projectId="A44BF93A-3829-456D-9B32-6D2F9FD94B83" />        
        <WaveReverse background="#F7F9FC" color="#fff" />         
        <VideoDemo title="Real Estate" name="Real Estate Brochure" projectId="F264F5EB-6ABA-4D68-BAD9-1B8EE7AFE480" />        
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <VideoDemo className="grey pb-20" title="Balmista" name="Shoppable Video" projectId="163BDABA-5460-4D52-9587-9B6293A291C9" />        
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
        <Commit />
        </div>

      </PageWrapper>
    </>
  );
};
export default Demo;
